import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getDjServicesUrl, getServiceUri } from "../../metadata";
import { updateMandatAmenerForm, loadMandatAmenerFormData } from "./MandatAmenerApi";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";
import { Section } from "../../../../auto/js/widgets/Section";

import { createTableComponent } from "../../widgets/TableComponent";
import TemplateComponent from "react-mustache-template-component";
import { loadFormData, mandatAmenerFields } from "./NewMandatAmenerForm";

	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		let MandatAmenerForm = createFormComponent(mandatAmenerFields);
		return (
		<>
			<MandatAmenerForm key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => getButtons(this.props.id)} />
		</>
		)
	}

}

const getButtons = (id) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
			<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => printMandatAmener(id)}> {t`Imprimer`} </button>
		</div>
	</div>
    </>
    )
}	

export const displayReadApprovedMandatAmenerForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} />
	};
}

export const printMandatAmener = (id) => {
	rest.read('forms/mandat-amener', id, getDjServicesUrl()).then(async m => {
		printTemplate(m)
	})
}

const printTemplate = (data) => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={dto2form(data.templateData)} />,
		copyParentStryle: false
	})
}

const dto2form = (dto) => {
	let form = dto; 
	if (dto.audienceDate != null)
		form.audienceDate = formatDate(dto.audienceDate);
	if (dto.date != null)
		form.date = formatDate(dto.date);
	return form;
}

const formatDate = (date) => {
	let d = (date[2] >= 10)?date[2]:"0"+date[2];
	let m = (date[1] >= 10)?date[1]:"0"+date[1];
	return d + "/" + m + "/" + date[0];
}