import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useConstructor } from "../utils";
import { OPEN_SIDE_PANEL } from "../events/Gui";

export const SidePanelComponent = (props) => {

    const [panelBody, setPanelBody] = useState(null);
    const [panelTitle, setPanelTitle] = useState("");
    const [openPanel, setOpenPanel] = useState(false)

    useConstructor(() => {
        OPEN_SIDE_PANEL.subscribe((panelBody, title) => {
			openSidePanel(panelBody, title);
		});
    })

    const openSidePanel = (panel) => {
		let panelBody = panel.body;
		let panelTitle = panel.title;
		setPanelBody(panelBody);
        setPanelTitle(panelTitle);
        setOpenPanel(true);
	}
	
	const closePanel = () => {
        setOpenPanel(false);
    }

    return (
        <SlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={openPanel}
            title={panelTitle}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                closePanel();
            }}
        >
            {panelBody}
      </SlidingPane>
    )
}