import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';

import { displayReadRegistreAppelForm } from "../../../../auto/js/forms/registreAppel/NewRegistreAppelForm";
import { displayRegistreAppelList } from "../../../../auto/js/lists/registreAppel/RegistreAppelList";
import { grey } from "@mui/material/colors";


export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    rowData.gui.goTo(displayReadRegistreAppelForm(() => displayRegistreAppelList(rowData.gui)), rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />  
            </IconButton>
        </Tooltip>
    )
}