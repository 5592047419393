export default class RateLimitingQueryService {
    constructor(accept, getData, time) {
        this.previousQuery = null; // Initialize with input string
        this.currentQuery = null; // Initialize with input string
        this.accept = accept; // Default value for option
        this.getData = getData;
        this.time = time;
    }

    setQuery(query) {
        this.currentQuery = query; // Set the option value
    }

    startTimer() {
        this.timer = setInterval(() => {
        if (this.currentQuery !== this.previousQuery && this.currentQuery !== "")
            this.getData(this.currentQuery).then(response => {
                this.accept(response)
            })
        this.previousQuery = this.currentQuery;
        }, this.time);
    }  

    stopTimer() {
        clearInterval(this.timer);
    }
}