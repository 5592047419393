export const pojoMetadata = {
	'records': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
		   { key: "face", name: "face", editable: true, resizable: true, type: "blob" },
		   { key: "fatherName", name: "father_name", editable: true, resizable: true, type: "text" },
		   { key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
		   { key: "idNumber", name: "id_number", editable: true, resizable: true, type: "text" },
		   { key: "idType", name: "id_type", editable: true, resizable: true, type: "number" },
		   { key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
		   { key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.birthdate != null && data.birthdate instanceof Date)
			data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
		if(data.fatherName == "")
			data.fatherName = null;
		if(data.firstname == "")
			data.firstname = null;
		if(data.idNumber == "")
			data.idNumber = null;
		if(data.lastname == "")
			data.lastname = null;
		if(data.othernames == "")
			data.othernames = null;
		return data
	}
},
'pv': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
		   { key: "plaintId", name: "plaint_id", editable: true, resizable: true, type: "number" },
		   { key: "pvDate", name: "pv_date", editable: true, resizable: true, type: "date" },
	],
	form2dto: (data) => {
		if(data.legacyId == "")
			data.legacyId = null;
		if(data.pvDate != null && data.pvDate instanceof Date)
			data.pvDate = data.pvDate.getFullYear() + "-" + ('0' + (data.pvDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.pvDate.getDate()).slice(-2);
		return data
	}
},
'complaint-record': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "complainantAddress", name: "complainant_address", editable: true, resizable: true, type: "text" },
		   { key: "complainantContactPreferences", name: "complainant_contact_preferences", editable: true, resizable: true, type: "number" },
		   { key: "complainantEmailAddress", name: "complainant_email_address", editable: true, resizable: true, type: "text" },
		   { key: "complainantFirstName", name: "complainant_first_name", editable: true, resizable: true, type: "text" },
		   { key: "complainantIdCardNumber", name: "complainant_id_card_number", editable: true, resizable: true, type: "number" },
		   { key: "complainantLastName", name: "complainant_last_name", editable: true, resizable: true, type: "text" },
		   { key: "complainantPhoneNumber", name: "complainant_phone_number", editable: true, resizable: true, type: "text" },
		   { key: "description", name: "description", editable: true, resizable: true, type: "text" },
		   { key: "desiredOutcome", name: "desired_outcome", editable: true, resizable: true, type: "text" },
		   { key: "evidence", name: "evidence", editable: true, resizable: true, type: "text" },
		   { key: "incidentCategory", name: "incident_category", editable: true, resizable: true, type: "number" },
		   { key: "incidentDate", name: "incident_date", editable: true, resizable: true, type: "date" },
		   { key: "incidentTime", name: "incident_time", editable: true, resizable: true, type: "time" },
		   { key: "jurisdiction", name: "jurisdiction", editable: true, resizable: true, type: "text" },
		   { key: "subjectAddress", name: "subject_address", editable: true, resizable: true, type: "text" },
		   { key: "subjectOfComplaint", name: "subject_of_complaint", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.complainantAddress == "")
			data.complainantAddress = null;
		if(data.complainantEmailAddress == "")
			data.complainantEmailAddress = null;
		if(data.complainantFirstName == "")
			data.complainantFirstName = null;
		if(data.complainantLastName == "")
			data.complainantLastName = null;
		if(data.complainantPhoneNumber == "")
			data.complainantPhoneNumber = null;
		if(data.description == "")
			data.description = null;
		if(data.desiredOutcome == "")
			data.desiredOutcome = null;
		if(data.incidentDate != null && data.incidentDate instanceof Date)
			data.incidentDate = data.incidentDate.getFullYear() + "-" + ('0' + (data.incidentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.incidentDate.getDate()).slice(-2);
		if(data.jurisdiction == "")
			data.jurisdiction = null;
		if(data.subjectAddress == "")
			data.subjectAddress = null;
		if(data.subjectOfComplaint == "")
			data.subjectOfComplaint = null;
		return data
	}
},
'victimes': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		return data
	}
},
'complaint-mtlb-tag': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "complaintMtlbId", name: "complaint_mtlb_id", editable: true, resizable: true, type: "number" },
		   { key: "content", name: "content", editable: true, resizable: true, type: "text" },
		   { key: "key", name: "key", editable: true, resizable: true, type: "text" },
		   { key: "type", name: "type", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.content == "")
			data.content = null;
		if(data.key == "")
			data.key = null;
		if(data.type == "")
			data.type = null;
		return data
	}
},
'jugement-record': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "date", name: "date", editable: true, resizable: true, type: "date" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "numeroCopie", name: "numero_copie", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.date != null && data.date instanceof Date)
			data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
		return data
	}
},
'registre-appel': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "file" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "judgmentDate", name: "judgment_date", editable: true, resizable: true, type: "date" },
		   { key: "judgmentNumber", name: "judgment_number", editable: true, resizable: true, type: "number" },
		   { key: "person", name: "person", editable: true, resizable: true, type: "number" },
		   { key: "transmissionDate", name: "transmission_date", editable: true, resizable: true, type: "date" },
	],
	form2dto: (data) => {
		if(data.judgmentDate != null && data.judgmentDate instanceof Date)
			data.judgmentDate = data.judgmentDate.getFullYear() + "-" + ('0' + (data.judgmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.judgmentDate.getDate()).slice(-2);
		if(data.transmissionDate != null && data.transmissionDate instanceof Date)
			data.transmissionDate = data.transmissionDate.getFullYear() + "-" + ('0' + (data.transmissionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.transmissionDate.getDate()).slice(-2);
		return data
	}
},
'e-id-mtlb-tag': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "content", name: "content", editable: true, resizable: true, type: "text" },
		   { key: "eIdMtlbId", name: "e_id_mtlb_id", editable: true, resizable: true, type: "number" },
		   { key: "key", name: "key", editable: true, resizable: true, type: "text" },
		   { key: "type", name: "type", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.content == "")
			data.content = null;
		if(data.key == "")
			data.key = null;
		if(data.type == "")
			data.type = null;
		return data
	}
},
'geo-data': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
		   { key: "disabled", name: "disabled", editable: true, resizable: true, type: "checkbox" },
		   { key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
		   { key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
		   { key: "name", name: "name", editable: true, resizable: true, type: "text" },
		   { key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
		   { key: "type", name: "type", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.areaId == "")
			data.areaId = null;
		if(data.flagMimeType == "")
			data.flagMimeType = null;
		if(data.name == "")
			data.name = null;
		return data
	}
},
'substitut-de-procureur-record': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "birthDate", name: "birth_date", editable: true, resizable: true, type: "date" },
		   { key: "idCard", name: "id_card", editable: true, resizable: true, type: "text" },
		   { key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
		   { key: "mail", name: "mail", editable: true, resizable: true, type: "text" },
		   { key: "name", name: "name", editable: true, resizable: true, type: "text" },
		   { key: "phone", name: "phone", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.birthDate != null && data.birthDate instanceof Date)
			data.birthDate = data.birthDate.getFullYear() + "-" + ('0' + (data.birthDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthDate.getDate()).slice(-2);
		if(data.idCard == "")
			data.idCard = null;
		if(data.lastName == "")
			data.lastName = null;
		if(data.mail == "")
			data.mail = null;
		if(data.name == "")
			data.name = null;
		if(data.phone == "")
			data.phone = null;
		return data
	}
},
'user-preferences': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "key", name: "key", editable: true, resizable: true, type: "text" },
		   { key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
		   { key: "value", name: "value", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.key == "")
			data.key = null;
		if(data.userId == "")
			data.userId = null;
		if(data.value == "")
			data.value = null;
		return data
	}
},
'registre-affectation': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "file" },
		   { key: "caseStatus", name: "case_status", editable: true, resizable: true, type: "number" },
		   { key: "complaintDate", name: "complaint_date", editable: true, resizable: true, type: "date" },
		   { key: "complaintTime", name: "complaint_time", editable: true, resizable: true, type: "time" },
		   { key: "complaintType", name: "complaint_type", editable: true, resizable: true, type: "number" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "initialInfraction", name: "initial_infraction", editable: true, resizable: true, type: "number" },
		   { key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
		   { key: "parquetRegion", name: "parquet_region", editable: true, resizable: true, type: "text" },
		   { key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "file" },
		   { key: "pvFile", name: "pv_file", editable: true, resizable: true, type: "file" },
		   { key: "pvId", name: "pv_id", editable: true, resizable: true, type: "text" },
		   { key: "soitTransmisFile", name: "soit_transmis_file", editable: true, resizable: true, type: "file" },
		   { key: "soitTransmisId", name: "soit_transmis_id", editable: true, resizable: true, type: "number" },
		   { key: "substitutIdCard", name: "substitut_id_card", editable: true, resizable: true, type: "text" },
		   { key: "unit", name: "unit", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.complaintDate != null && data.complaintDate instanceof Date)
			data.complaintDate = data.complaintDate.getFullYear() + "-" + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.complaintDate.getDate()).slice(-2);
		if(data.legacyId == "")
			data.legacyId = null;
		if(data.parquetRegion == "")
			data.parquetRegion = null;
		if(data.pvId == "")
			data.pvId = null;
		if(data.substitutIdCard == "")
			data.substitutIdCard = null;
		return data
	}
},
'audience': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "file" },
		   { key: "convocationDesTemoins", name: "convocation_des_temoins", editable: true, resizable: true, type: "file" },
		   { key: "date", name: "date", editable: true, resizable: true, type: "date" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "mandatDAmener", name: "mandat_d_amener", editable: true, resizable: true, type: "file" },
		   { key: "mandatDArret", name: "mandat_d_arret", editable: true, resizable: true, type: "file" },
		   { key: "mandatDeDepot", name: "mandat_de_depot", editable: true, resizable: true, type: "file" },
		   { key: "time", name: "time", editable: true, resizable: true, type: "time" },
	],
	form2dto: (data) => {
		if(data.date != null && data.date instanceof Date)
			data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
		return data
	}
},
'address': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
		   { key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.areaId == "")
			data.areaId = null;
		return data
	}
},
'registre-poursuite': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "file" },
		   { key: "caseStatus", name: "case_status", editable: true, resizable: true, type: "number" },
		   { key: "complaintDate", name: "complaint_date", editable: true, resizable: true, type: "date" },
		   { key: "complaintTime", name: "complaint_time", editable: true, resizable: true, type: "time" },
		   { key: "complaintType", name: "complaint_type", editable: true, resizable: true, type: "number" },
		   { key: "enrollmentDate", name: "enrollment_date", editable: true, resizable: true, type: "date" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "initialInfraction", name: "initial_infraction", editable: true, resizable: true, type: "number" },
		   { key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
		   { key: "parquetRegion", name: "parquet_region", editable: true, resizable: true, type: "text" },
		   { key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "file" },
		   { key: "pvFile", name: "pv_file", editable: true, resizable: true, type: "file" },
		   { key: "pvId", name: "pv_id", editable: true, resizable: true, type: "text" },
		   { key: "soitTransmisId", name: "soit_transmis_id", editable: true, resizable: true, type: "text" },
		   { key: "soitTransmitFile", name: "soit_transmit_file", editable: true, resizable: true, type: "file" },
		   { key: "unit", name: "unit", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.complaintDate != null && data.complaintDate instanceof Date)
			data.complaintDate = data.complaintDate.getFullYear() + "-" + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.complaintDate.getDate()).slice(-2);
		if(data.enrollmentDate != null && data.enrollmentDate instanceof Date)
			data.enrollmentDate = data.enrollmentDate.getFullYear() + "-" + ('0' + (data.enrollmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.enrollmentDate.getDate()).slice(-2);
		if(data.legacyId == "")
			data.legacyId = null;
		if(data.parquetRegion == "")
			data.parquetRegion = null;
		if(data.pvId == "")
			data.pvId = null;
		if(data.soitTransmisId == "")
			data.soitTransmisId = null;
		return data
	}
},
'complaint-mtlb': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "file" },
		   { key: "complainantAddress", name: "complainant_address", editable: true, resizable: true, type: "text" },
		   { key: "complainantContactPreferences", name: "complainant_contact_preferences", editable: true, resizable: true, type: "number" },
		   { key: "complainantEmailAddress", name: "complainant_email_address", editable: true, resizable: true, type: "text" },
		   { key: "complainantFirstName", name: "complainant_first_name", editable: true, resizable: true, type: "text" },
		   { key: "complainantIdCardNumber", name: "complainant_id_card_number", editable: true, resizable: true, type: "number" },
		   { key: "complainantLastName", name: "complainant_last_name", editable: true, resizable: true, type: "text" },
		   { key: "complainantPhoneNumber", name: "complainant_phone_number", editable: true, resizable: true, type: "text" },
		   { key: "complaintRecordId", name: "complaint_record_id", editable: true, resizable: true, type: "number" },
		   { key: "description", name: "description", editable: true, resizable: true, type: "text" },
		   { key: "desiredOutcome", name: "desired_outcome", editable: true, resizable: true, type: "text" },
		   { key: "evidence", name: "evidence", editable: true, resizable: true, type: "file" },
		   { key: "incidentCategory", name: "incident_category", editable: true, resizable: true, type: "number" },
		   { key: "incidentDate", name: "incident_date", editable: true, resizable: true, type: "date" },
		   { key: "incidentTime", name: "incident_time", editable: true, resizable: true, type: "time" },
		   { key: "jurisdiction", name: "jurisdiction", editable: true, resizable: true, type: "text" },
		   { key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
		   { key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
		   { key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
		   { key: "pvId", name: "pv_id", editable: true, resizable: true, type: "number" },
		   { key: "subjectAddress", name: "subject_address", editable: true, resizable: true, type: "text" },
		   { key: "subjectOfComplaint", name: "subject_of_complaint", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.complainantAddress == "")
			data.complainantAddress = null;
		if(data.complainantEmailAddress == "")
			data.complainantEmailAddress = null;
		if(data.complainantFirstName == "")
			data.complainantFirstName = null;
		if(data.complainantLastName == "")
			data.complainantLastName = null;
		if(data.complainantPhoneNumber == "")
			data.complainantPhoneNumber = null;
		if(data.description == "")
			data.description = null;
		if(data.desiredOutcome == "")
			data.desiredOutcome = null;
		if(data.incidentDate != null && data.incidentDate instanceof Date)
			data.incidentDate = data.incidentDate.getFullYear() + "-" + ('0' + (data.incidentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.incidentDate.getDate()).slice(-2);
		if(data.jurisdiction == "")
			data.jurisdiction = null;
		if(data.legacyId == "")
			data.legacyId = null;
		if(data.subjectAddress == "")
			data.subjectAddress = null;
		if(data.subjectOfComplaint == "")
			data.subjectOfComplaint = null;
		return data
	}
},
'mis-cause': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		return data
	}
},
'i18n': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
		   { key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
		   { key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
		   { key: "value", name: "value", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.domainId == "")
			data.domainId = null;
		if(data.localeId == "")
			data.localeId = null;
		if(data.messageId == "")
			data.messageId = null;
		if(data.value == "")
			data.value = null;
		return data
	}
},
'registre-affectation-record': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
		   { key: "complaintDate", name: "complaint_date", editable: true, resizable: true, type: "date" },
		   { key: "complaintTime", name: "complaint_time", editable: true, resizable: true, type: "time" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "initialInfraction", name: "initial_infraction", editable: true, resizable: true, type: "number" },
		   { key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "number" },
		   { key: "parquetRegion", name: "parquet_region", editable: true, resizable: true, type: "text" },
		   { key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "text" },
		   { key: "pvFile", name: "pv_file", editable: true, resizable: true, type: "text" },
		   { key: "pvId", name: "pv_id", editable: true, resizable: true, type: "number" },
		   { key: "soitTransmisFile", name: "soit_transmis_file", editable: true, resizable: true, type: "text" },
		   { key: "soitTransmisId", name: "soit_transmis_id", editable: true, resizable: true, type: "number" },
		   { key: "substitutIdCard", name: "substitut_id_card", editable: true, resizable: true, type: "text" },
		   { key: "unit", name: "unit", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.complaintDate != null && data.complaintDate instanceof Date)
			data.complaintDate = data.complaintDate.getFullYear() + "-" + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.complaintDate.getDate()).slice(-2);
		if(data.parquetRegion == "")
			data.parquetRegion = null;
		if(data.substitutIdCard == "")
			data.substitutIdCard = null;
		if(data.unit == "")
			data.unit = null;
		return data
	}
},
'registre-opposition': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "file" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "judgmentDate", name: "judgment_date", editable: true, resizable: true, type: "date" },
		   { key: "judgmentNumber", name: "judgment_number", editable: true, resizable: true, type: "number" },
		   { key: "person", name: "person", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.judgmentDate != null && data.judgmentDate instanceof Date)
			data.judgmentDate = data.judgmentDate.getFullYear() + "-" + ('0' + (data.judgmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.judgmentDate.getDate()).slice(-2);
		return data
	}
},
'folder': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "folderStatus", name: "folder_status", editable: true, resizable: true, type: "number" },
		   { key: "name", name: "name", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.name == "")
			data.name = null;
		return data
	}
},
'audience-record': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
		   { key: "convocationDesTemoins", name: "convocation_des_temoins", editable: true, resizable: true, type: "text" },
		   { key: "date", name: "date", editable: true, resizable: true, type: "date" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "mandatDAmener", name: "mandat_d_amener", editable: true, resizable: true, type: "text" },
		   { key: "mandatDArret", name: "mandat_d_arret", editable: true, resizable: true, type: "text" },
		   { key: "mandatDeDepot", name: "mandat_de_depot", editable: true, resizable: true, type: "text" },
		   { key: "time", name: "time", editable: true, resizable: true, type: "time" },
	],
	form2dto: (data) => {
		if(data.date != null && data.date instanceof Date)
			data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
		return data
	}
},
'issued-document': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "cancelledDate", name: "cancelled_date", editable: true, resizable: true, type: "date" },
		   { key: "cancelledReason", name: "cancelled_reason", editable: true, resizable: true, type: "text" },
		   { key: "documentId", name: "document_id", editable: true, resizable: true, type: "text" },
		   { key: "expires", name: "expires", editable: true, resizable: true, type: "date" },
		   { key: "fullName", name: "full_name", editable: true, resizable: true, type: "text" },
		   { key: "idCardNumber", name: "id_card_number", editable: true, resizable: true, type: "number" },
		   { key: "issuedDate", name: "issued_date", editable: true, resizable: true, type: "date" },
		   { key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.cancelledDate != null && data.cancelledDate instanceof Date)
			data.cancelledDate = data.cancelledDate.getFullYear() + "-" + ('0' + (data.cancelledDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.cancelledDate.getDate()).slice(-2);
		if(data.cancelledReason == "")
			data.cancelledReason = null;
		if(data.documentId == "")
			data.documentId = null;
		if(data.expires != null && data.expires instanceof Date)
			data.expires = data.expires.getFullYear() + "-" + ('0' + (data.expires.getMonth() + 1)).slice(-2) + "-" + ('0' + data.expires.getDate()).slice(-2);
		if(data.fullName == "")
			data.fullName = null;
		if(data.issuedDate != null && data.issuedDate instanceof Date)
			data.issuedDate = data.issuedDate.getFullYear() + "-" + ('0' + (data.issuedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.issuedDate.getDate()).slice(-2);
		if(data.notes == "")
			data.notes = null;
		return data
	}
},
'e-id-mtlb': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "documentId", name: "document_id", editable: true, resizable: true, type: "text" },
		   { key: "firstName", name: "first_name", editable: true, resizable: true, type: "text" },
		   { key: "idCardNumber", name: "id_card_number", editable: true, resizable: true, type: "number" },
		   { key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
		   { key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
		   { key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
		   { key: "password", name: "password", editable: true, resizable: true, type: "password" },
	],
	form2dto: (data) => {
		if(data.documentId == "")
			data.documentId = null;
		if(data.firstName == "")
			data.firstName = null;
		if(data.lastName == "")
			data.lastName = null;
		if(data.password == "")
			data.password = null;
		return data
	}
},
'substitut-de-procureur': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "birthDate", name: "birth_date", editable: true, resizable: true, type: "date" },
		   { key: "idCard", name: "id_card", editable: true, resizable: true, type: "text" },
		   { key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
		   { key: "mail", name: "mail", editable: true, resizable: true, type: "text" },
		   { key: "name", name: "name", editable: true, resizable: true, type: "text" },
		   { key: "phone", name: "phone", editable: true, resizable: true, type: "text" },
	],
	form2dto: (data) => {
		if(data.birthDate != null && data.birthDate instanceof Date)
			data.birthDate = data.birthDate.getFullYear() + "-" + ('0' + (data.birthDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthDate.getDate()).slice(-2);
		if(data.idCard == "")
			data.idCard = null;
		if(data.lastName == "")
			data.lastName = null;
		if(data.mail == "")
			data.mail = null;
		if(data.name == "")
			data.name = null;
		if(data.phone == "")
			data.phone = null;
		return data
	}
},
'jugement': {
	columns: [
		   { key: "id", name: "id", editable: false, resizable: true},
		   { key: "date", name: "date", editable: true, resizable: true, type: "date" },
		   { key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
		   { key: "numeroCopie", name: "numero_copie", editable: true, resizable: true, type: "number" },
	],
	form2dto: (data) => {
		if(data.date != null && data.date instanceof Date)
			data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
		return data
	}
},
}

export const buildEmptyObject = (entity) => {
var columns = new Object();
let fields = pojoMetadata[entity].columns
for (let col in fields) {		
if(fields[col].type == 'text'){
	columns[fields[col].key] = "";
}else if(fields[col].type=='checkbox'){
	columns[fields[col].key] = false;
}else if(fields[col].type=='datetime-local'){
	columns[fields[col].key] = "";
}
else if(fields[col].type=='autocomplete'){
	columns[fields[col].key] = null;
}
}
return columns
};

export const getEntityIdCol = (entity) => {
return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
return row[getEntityIdCol(entity)];
}

