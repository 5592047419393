import React, { Component } from "react";
import { Chip, TextField, Autocomplete, Button, FormControl, InputLabel, Paper, createFilterOptions } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveCircleOutlineSharp from '@mui/icons-material/RemoveCircleOutlineSharp';
import { Field, withFormik } from "formik";
import { t } from "../../../auto/js/services";
import AutocompleteDynamicListSelector from "./AutocompleteDynamicListSelector";


class AutocompleteDynamicListSelectorField extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>
                <Field name={this.props.name} component={AutocompleteDynamicListSelector} {...this.props}/>
                
            </div>
        );
    }
}

export default AutocompleteDynamicListSelectorField;
