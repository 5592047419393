
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'

import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'

import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';

import { displayRegionList } from '../../../auto/js/lists/metadata/RegionList';
import { displayNewRegionForm } from '../../../auto/js/forms/metadata/RegionForm';
import { displayDistrictList } from '../../../auto/js/lists/metadata/DistrictList';
import { displayNewDistrictForm } from '../../../auto/js/forms/metadata/DistrictForm';
import { displayAudienceList } from '../../../auto/js/lists/audience/AudienceList';
import { displayJugementList } from '../../../auto/js/lists/jugement/JugementList';
import { displayRegistreOppositionList } from '../../../auto/js/lists/registreOpposition/RegistreOppositionList';
import { displayRegistreAppelList } from '../../../auto/js/lists/registreAppel/RegistreAppelList';
import { displayMandatAmenerList } from '../../../auto/js/lists/mandatAmener/MandatAmenerList';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('JUGE') || whoami().roles.includes('PRESIDENT') || whoami().roles.includes('GREFFIER_OA')) && {
			"opposition": {
				submenu: {
                    "ro": {
						options: {
							applications: { label: "Entrés", do: (gui) => displayRegistreOppositionList(gui)}
						},
						label: "Registre d'opposition"
					}
				},
				label: "Opposition"
			}
        }),
		...((whoami().roles.includes('ALL')|| whoami().roles.includes('JUGE') || whoami().roles.includes('PRESIDENT') || whoami().roles.includes('GREFFIER_OA')) && {
			"appel": {
				submenu: {
                    "rap": {
						options: {
							applications: { label: "Entrés", do: (gui) => displayRegistreAppelList(gui)}
						},
						label: "Registre d'appel"
					}
				},
				label: "Appel"
			}
        }),
		...((whoami().roles.includes('ALL')|| whoami().roles.includes('JUGE') || whoami().roles.includes('PRESIDENT') 
		|| whoami().roles.includes('GREFFIER_PRISON') || whoami().roles.includes('GREFFIER_CHEF_PRISON') || whoami().roles.includes('GREFFIER_CC')) && {
			"audience": {
				submenu: {
					...(whoami().roles.includes('ALL') || whoami().roles.includes('PRESIDENT') || whoami().roles.includes('JUGE') || whoami().roles.includes('GREFFIER_CC')) && {
                    "audience": {
						options: {
							applications: { label: "Entrés", do: (gui) => displayAudienceList(gui)}
						},
						label: "Registre d'audience"
					}},
					...(whoami().roles.includes('ALL') || whoami().roles.includes('PRESIDENT') || whoami().roles.includes('JUGE') || whoami().roles.includes('GREFFIER_CC') || whoami().roles.includes('GREFFIER_PRISON') || whoami().roles.includes('GREFFIER_CHEF_PRISON') ) && {
						"ma": {
							options: {
								applications: { label: "Entrés", do: (gui) => displayMandatAmenerList(gui)}
							},
							label: "Mandat d'amener"
						}}
				},
				label: "Audience"
			}
        }), 
		...((whoami().roles.includes('ALL')|| whoami().roles.includes('JUGE') || whoami().roles.includes('PRESIDENT') || whoami().roles.includes('GREFFIER_OA')) && {
			"jugement": {
				submenu: {
                    "jugement": {
						options: {
							applications: { label: "Entrés", do: (gui) => displayJugementList(gui)}
						},
						label: "Registre de jugement"
					}
				},
				label: "Jugement"
			}
        }),       
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {

                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                    "region": {
                        options: {
                            list: {label: "Regions", do: (gui) => displayRegionList(gui)},
                            new: {label: "New", do: (gui) => displayNewRegionForm(gui)},
                        },
                        label: "Region"
                    },
                    "distric": {
                        options: {
                            list: {label: "Districts", do: (gui) => displayDistrictList(gui)},
                            new: {label: "New", do: (gui) => displayNewDistrictForm(gui)},
                        },
                        label: "District"
                    },
                },
                label: "Configuration"
            }
        })
    }
}
export const menuType = RIBBON_MENU;
