import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getDjServicesUrl, getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    transformAdvancedSearchData,
    advancedSearchfields,
    getRowActions
} from "../../../../main/js/lists/registreAppel/RegistreAppelListCommon.jsx";


const registreAppelListFields = [
    {
        title: "ID",
        field: "id",
    },
    {
        title: "Case ID",
        field: "folderId",
    },
    { title: "Plaignants", field: "plaignants" },
    { title: "Mis en Causes", field: "misCause" },
    { title: "Victimes", field: "victimes" },
    {
        title: "transmissionDate",
        field: "transmissionDate",
    },
    {
        title: "Actions",
        field: "actions",
        render: (rowData) => getRowActions(rowData)
    }
]

const RegistreAppelListPage = ({uuid, gui, searchData}) => {
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let RegistreAppelTable = createTableComponent(registreAppelListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["registre-appel-document"] = {}
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["registre-appel"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["registre-appel-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            return filterData(response, gui).then(data => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }


    return (
        <div>

           <RegistreAppelTable key={uuid} loadData={async (query) => buildData(query)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displayRegistreAppelList = (gui) => {
	const readRegistreAppelList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RegistreAppelListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readRegistreAppelList)
}


/* const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
            tooltip: t`Edit`,
            onClick: (event, rowData) => {
            }
        }
    )
    return actions;
} */

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "registre-appel/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "registre-appel/count-document/", "POST", filter);
}

export const filterData = async (defaultRows, gui) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        let date = row.transmissionDate;
        if (date != null) {
            row.transmissionDate = date[2] + "-" + date[1] + "-" + date[0];
        }
        row.gui = gui;
        let plaignants = '';
            let personFilter = {and: true};
            personFilter['plaignants'] = {folderId: row.folderId};
            await rest.search('plaignants-records', personFilter, getDjServicesUrl()).then(p => {
                if (p.length) {
                    let first = true;
                    p.forEach(element => {
                        if (first) {
                            plaignants = element.firstname;
                            first = false;
                        } 
                        else {
                            plaignants = plaignants.concat(', ', element.firstname)
                        }
                        if (element.lastname != null) 
                            plaignants = plaignants.concat(' ', element.lastname)
                    });
                }
                
            })
            row.plaignants = plaignants;
    
            let victimes = '';
            let vFilter = {and: true};
            vFilter['victimes'] = {folderId: row.folderId};
            await rest.search('victimes-records', vFilter, getDjServicesUrl()).then(p => {
                if (p.length) {
                    let first = true;
                    p.forEach(element => {
                        if (first) {
                            victimes = element.firstname;
                            first = false;
                        } 
                        else {
                            victimes = victimes.concat(', ', element.firstname)
                        }
                        if (element.lastname != null) 
                            victimes = victimes.concat(' ', element.lastname)
                    });
                }
                
            })
            row.victimes = victimes;
    
            let misCause = '';
            let mFilter = {and: true};
            mFilter['misCause'] = {folderId: row.folderId};
            await rest.search('mis-causes-records', mFilter, getDjServicesUrl()).then(p => {
                if (p.length) {
                    let first = true;
                    p.forEach(element => {
                        if (first) {
                            misCause = element.firstname;
                            first = false;
                        } 
                        else {
                            misCause = misCause.concat(', ', element.firstname)
                        }
                        if (element.lastname != null) 
                            misCause = misCause.concat(' ', element.lastname)
                    });
                }
                
            })
            row.misCause = misCause;
        newRows.push(row);
    };
    return newRows;
}