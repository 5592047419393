import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';

import { displayReadRegistreOppositionForm } from "../../../../auto/js/forms/registreOpposition/NewRegistreOppositionForm";
import { displayRegistreOppositionList } from "../../../../auto/js/lists/registreOpposition/RegistreOppositionList";
import { grey } from "@mui/material/colors";

export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    rowData.gui.goTo(displayReadRegistreOppositionForm(() => displayRegistreOppositionList(rowData.gui)), rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}