export const customRegistreOppositionForm2Dto = (formData, dto) => {

}

export const newRegistreOpposionForm2FormDto = (formData, dto) => {
    dto.judgmentNumber = formData.id;
    dto.id = null;
    dto.judgmentDate = formData.date;
}

export const customRegistreOppositionDto2Form = (dto, form) => {

}