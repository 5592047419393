import React, { useState } from "react";
import { Chip, TextField, Autocomplete, Button, FormControl, InputLabel } from '@mui/material';
import RemoveCircleOutlineSharp from '@mui/icons-material/RemoveCircleOutlineSharp';
import { useField } from "formik";
import { loadingIcon } from "../loadingIcon";
import { t } from "../services";

/**
 * Transforms the input as described in the api to the form required by the
 * GUI components
 * 
 * @param allOptions The options object as provided to the Widget
 * @param list The list of IDs of the options displayed as chips
 * @returns An array of objects in the form {id: number, label: str}
 *    that stores the options that are referenced in the list
 */
function preprocessInput(allOptions, list) {
    let options = [];

    if (list != null)
        list = list.map((elem) => String(elem));
    else
        list = [];
    for (const [key, value] of Object.entries(allOptions)) {
      if (!list.includes(key)) {
        options.push({
            "id": parseInt(key),
            "label": value
        });
      }
    }

    return options;
}

/**
 * 
 * @param options object in the form {id1: label1, id2: label2...} representing all possible options
 * for the auto complete
 * @param props must contain a "name" property
 */
function AutocompleteListSelector(props) {
    const [field, meta, helpers] = useField(props.name);
    const [options, setOptions] = useState(undefined);
    const [inputValue, setInputValue] = useState(undefined);
    const value = meta.value;
    const setValue = helpers.setValue;

    if(props.options)
      setOptions(props.options);

    if(!options) {
      if(props.loadOptions)
        props.loadOptions().then(response => {
          setOptions(response);
        })
      return loadingIcon
    }

/*     const handleInputChange = async (filter) => {
      if (props.searchRequest)
        props.searchRequest(filter).then(response => {
          setOptions(response);
        })
    } */

    const handleChange = (event, newValue, reason) => {
      setValue([...value, newValue.id]);
      /* let v = value;
      v[newValue.id] = newValue.label;
      setValue(v); */
    }

    return (
        <div>
        <FormControl variant="standard">
          <InputLabel shrink htmlFor={props.name}>
            {props.label}
          </InputLabel>
           <Autocomplete
            className={props.layout}
            id="tags-standard"
            readOnly={props.readOnly}
            filterSelectedOptions
            options={preprocessInput(options, value)}
            getOptionLabel={option => option.label}
            onChange={handleChange}
/*             onInputChange={async (event, newInputValue) => {
              handleInputChange(newInputValue);
              setInputValue(newInputValue)
            }} */
            aria-label={props.label}
            renderTags={() => {}}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder={props.label}
                margin="normal"
                fullWidth
                label={props.label}
                InputLabelProps={{shrink: false}}
              />
            )}
          />
          </FormControl>
          <div className="selected-tags">
            {
              value.map((obj, index) => {
                return <Chip
                    key={index}
                    label={options[obj]}
                    deleteIcon={<RemoveCircleOutlineSharp />}
                    onDelete={() => {
                      const updatedChips = value.filter(entry => entry !== obj);
                      setValue(updatedChips);
                    }}
                />
              })
            }
          </div>
        </div>
      )
}

export default AutocompleteListSelector;